import { Box, Flex, Grid, GridItem, Link, LinkBox, LinkOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import NextLink from 'next/link';
import SubNavGrid, { SubNavProps } from "../SubNavGrid";
import Image from "next/image"
import { SubNavPrimaryLink } from "../SubNavPrimaryLink";
import tripJournalImage from "../../../../public/images/mission-trip-journals.png"
import { SubNavSideBox } from "../SubNavSideBox";


const TripJournalsSubNav = ({ openNavItem, label, globalState }: SubNavProps): JSX.Element => {
  return (
    <SubNavGrid
      visible={openNavItem === label}
      openNavItem={openNavItem}
      gap={5}
      templateColumns={'3fr 4fr'}
    >

     <Flex flexDirection={'column'} justifyContent={'center'}>
        <SubNavPrimaryLink
          href='/mission-trip-journals'
          title="Mission Trip Journals"
          text="Follow along with teams as they go on mission, and read about the experiences of past teams who have served with Praying Pelican."
          image={tripJournalImage}
          imageAlt="Map with pins"
        ></SubNavPrimaryLink>
      </Flex>

 
        <SubNavSideBox heading=" Recent Trip Journals">
          <Grid templateColumns={{base: "1fr", lg: "1fr 1fr"}} gap={3}>
            {globalState.recentJournals?.map((journal, index) => (
              <RecentJournalLink key={index} journal={journal} />
            ))}
          </Grid>
        </SubNavSideBox>
   


    </SubNavGrid>
  )
}

export const RecentJournalLink = ({ journal, textColor }: { journal: RecentJournal, textColor?: string }) => {
  return (
    <Flex key={journal.journalID} gap="3" alignItems={'center'}>
      <LinkBox>
        <NextLink href={`/mission-trip-journals/${journal.journalTrip}`} passHref >
          <LinkOverlay>
            <Box
              rounded="lg"
              overflow="hidden"
              position="relative"
              w="100px"
              h="60px"
              minW="100px"

            >
              <Image
                src={`https://trip-journals.storage.googleapis.com/${journal.journalTrip}/thumb/${journal.journalID}.jpg`}
                alt={journal.entryTitle}
                layout="fill"
                objectFit={'cover'}
                width={100}
                height={60}
              />
            </Box>
          </LinkOverlay>
        </NextLink>
      </LinkBox>
      <Flex justify="center" direction="column">
        <NextLink href={`/mission-trip-journals/${journal.journalTrip}#entry-${journal.journalID}`} passHref >
          <Link textStyle={'tripJournalNavLink'}>
            {journal.entryTitle}
          </Link>
        </NextLink>
        <Text textStyle={'tripJournalNavInfo'} color={textColor ?? 'brand.darkgray.600'}>
          {journal.groupname}
        </Text>
        <Text textStyle={'tripJournalNavInfo'} color={textColor ?? 'brand.darkgray.600'}>
          {journal.locationName} | {journal.journalDate}
        </Text>
      </Flex>
    </Flex>
  )
}

export default TripJournalsSubNav;