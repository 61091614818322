import { Flex, Text } from "@chakra-ui/react";
import Link from "next/link";

const FooterBottomBar = () => {
  return (
    <Flex 
      justifyContent={{base: 'center', lg: "flex-end"}} 
      alignItems={'center'} 
      direction={{ base: "column", lg: "row" }}
      gap="2"
    >
      <Link href="/privacy-policy" passHref ><Text as="a">Privacy Policy</Text></Link>
      <Text as="span" display={{base: 'none', lg: 'inherit'}} color="brand.darkgray.200">|</Text>
      <Link href="/terms-and-conditions" passHref ><Text as="a">Terms &amp; Conditions</Text></Link>
      <Text as="span" display={{base: 'none', lg: 'inherit'}} color="brand.darkgray.200">|</Text>
      <Text as="span" color="brand.darkgray.200">&copy; {new Date().getFullYear()}</Text>
      <Link href="/" passHref ><Text as="a">Praying Pelican Missions</Text></Link>
    </Flex>
  )
}

export default FooterBottomBar;