import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  Input,
  FormControl,
  VisuallyHidden,
  FormLabel,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Image from "next/image";
import Link from "next/link";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  setAlertModalData: Dispatch<SetStateAction<AlertModalProps>>;
  openAlertModal: () => void;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const SignOutModal = (props: Props): JSX.Element => {
  const [signingOut, setSigningOut] = useState<boolean>(false);

  const handleSignOut = async () => {
    try {
      setSigningOut(true);

      const signOut = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/authenticate`, {method: 'DELETE',  credentials: 'include'})

      if (signOut.ok) {
        props.setAlertModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Signed Out',
          content: `You have been signed out.`
        });

        props.onClose();
        props.openAlertModal();
        
        // delete token cookie
        document.cookie = 'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';

        props.setGlobalState({
          ...props.globalState,
          user: null
        })

      } else {
        console.error(signOut);
        throw new Error('Failed to sign out')
      }

      props.openAlertModal();

    } catch (error) {
      console.error(error);

      props.setAlertModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error signing you out. Please try again later.'
      });

      props.openAlertModal();
    }
    
    setSigningOut(false);
  }

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center w="100%" flexDirection="column">
            <Image src={'/images/praying-pelican-logo.png'} alt="Praying Pelican Missions Logo" width={2355 / 10} height={988 / 10} />
            <Text as="h2" fontSize="2xl">
              Signed In
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text textAlign={'center'}>
            You are currently signed in as {props.globalState?.user?.name} ({props.globalState?.user?.email}).
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center" flexDirection={"row"} gap="3">
          <Button
            colorScheme="brand.darkgray"
            onClick={handleSignOut}
            disabled={signingOut}
          >
            Sign out
          </Button>
          <Link href="https://account.ppm.org/" passHref >
            <Button
              as="a"
              colorScheme="brand.green"
              disabled={signingOut}
              sx={{
                _hover: { textDecoration: 'none' }
              }}
            >
              Go To Pelican Account
            </Button>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SignOutModal;