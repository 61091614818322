import Link from "next/link"

export interface DisasterResponseLinkProps {
    disaster: DisasterResponse,
    children: React.ReactNode
}
export const DisasterResposneLink = ({disaster, children}: DisasterResponseLinkProps) => {

    return (
        <Link href={`/response/${disaster.url}`}>
            {children}
        </Link>
    )
}