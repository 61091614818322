import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Center,
  Box,
  Text,
  ModalFooter,
  Button,
  Input,
  FormControl,
  VisuallyHidden,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Image from "next/image";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  setAlertModalData: Dispatch<SetStateAction<AlertModalProps>>;
  openAlertModal: () => void;
}

const ResetPasswordModal = (props: Props): JSX.Element => {
  const { register, handleSubmit, formState } = useForm<{email: string;}>({
    defaultValues: {
      email: '',
    }
  });

  const handleSendResetEmail = async (values) => {
    try {
      const sendForm = await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/users/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })

      if (sendForm.ok) {
        props.setAlertModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Password Reset Email Sent',
          content: `Password reset instructions have been emailed to ${values.email}.`
        });

        props.onClose();
        props.openAlertModal();

      } else {
        console.error(sendForm);
        throw new Error('Failed to send password reset email')
      }

      props.openAlertModal();

    } catch (error) {
      console.error(error);
      props.setAlertModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error submitting your information. Please try again later.'
      });

      props.openAlertModal();
    }
  }

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleSendResetEmail)}>
          <ModalHeader>
            <Center w="100%" flexDirection="column" gap="5">
              <Image src={'/images/praying-pelican-logo.png'} alt="Praying Pelican Missions Logo" width={2355 / 10} height={988 / 10} />
              <Text as="h2" fontSize="2xl">
                Reset Password
              </Text>
            </Center>
          </ModalHeader>
          <ModalBody>
            <Text fontSize="lg" color="brand.darkgray.600" textAlign="center">
              Enter the email address associated with your account and we will email you a link to reset your password.
            </Text>
            <FormControl
              borderRadius={'lg'}
              isInvalid={!!formState.errors['email']}
              isDisabled={formState.isSubmitting}
            >
              <VisuallyHidden>
                <FormLabel htmlFor={'email'}>
                  Email
                </FormLabel>
              </VisuallyHidden>

              <Input
                name={'email'}
                type={'email'}
                variant="outline"
                size={'md'}
                layerStyle={'lightForm'}
                placeholder={'Email Address'}
                {...register('email', { required: 'Email is required' })}
                border="1px solid"
                borderColor={'brand.orange.600'}
              />

              <FormErrorMessage>
                <Box textAlign="center" w="full">
                  {formState.errors['email']?.message}
                </Box>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button type="submit" colorScheme="brand.green">
              Send Reset Email
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ResetPasswordModal;