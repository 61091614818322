import { Box, Flex, Icon, Stack, Text, Link as ChakraLink, Container, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { cloneElement, useEffect, useState } from "react";

const DesktopNav = ({ navigationItems }) => {
  const [openNavItem, setOpenNavItem] = useState<string | undefined>(undefined);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', handleCloseNav);

    return () => {
      router.events.off('routeChangeStart', handleCloseNav);
    };
  }, [router]);

  const handleCloseNav = () => setOpenNavItem(undefined);

  useEffect(() => {
    window.addEventListener('click', handleDesktopNavClick);
    return () => window.removeEventListener('click', handleDesktopNavClick);
  })

  const handleDesktopNavClick = (e: any) => {
    const mainNavItemClicked = e.target.classList.contains('main-nav-item') || e.target.closest('.main-nav-item');
    const clickedNavOrSubNav: boolean = e.target.closest('.main-nav-item') || e.target.closest('#main-sub-nav');
    
    if (mainNavItemClicked) {
      const thisNavItem = e.target.parentElement?.dataset.navItem || e.target.dataset.navItem || e.target.closest('.main-nav-item').dataset.navItem;

      if (thisNavItem) {
        if (thisNavItem == 'search') {
            focusGoogleSearchInput();
        }
        if (openNavItem === thisNavItem) {
          setOpenNavItem(undefined);
        } else {
          setOpenNavItem(thisNavItem);
        }
      }
    } else if (!clickedNavOrSubNav) {
      setOpenNavItem(undefined);
    }
  }

  const focusGoogleSearchInput = () => {
    const timeout = openNavItem ? 200 : 400;
    setTimeout(() => {
      const searchBar = document.getElementById('gsc-i-id1');
      if (searchBar) {
        searchBar.focus();
      }
    }, timeout);
  }

  return (
    <>
      <DesktopMainNav openNavItem={openNavItem} navigationItems={navigationItems} />
      <DesktopSubNav openNavItem={openNavItem} navigationItems={navigationItems} />
    </>
  );
}

const DesktopMainNav = ({openNavItem, navigationItems }) => {
  return (
    <Flex display={{ base: 'none', lg: 'flex' }} flexDirection="column" ml={10} id="main-nav">
      <Stack
        direction={'row'}
        spacing={20}
        justifyContent={'space-between'}
        alignItems={'center'}
        height="100%"
      >
        <Stack
          direction={'row'}
          spacing={3}
          justifyContent={'start'}
          alignItems={'center'}
          height={'100%'} 
        >
          <NextLink href={'/mission-trip-dates'} passHref >
            <Button 
              size="md"
              as="a"
              lineHeight={1}
              sx={{ '&:hover': { textDecoration: 'none' } }}
              variant={'outline'}
              colorScheme={'brand.green'} 
              leftIcon={<FontAwesomeIcon icon={['far', 'calendar']} />}
            >
              VIEW TRIP DATES
            </Button>
          </NextLink>
          <NextLink href={'/donate'} passHref >
            <Button 
              size="md"
              as="a"
              lineHeight={1}
              sx={{ '&:hover': { textDecoration: 'none' } }}
              variant={'outline'}
              colorScheme={'brand.green'} 
              leftIcon={<FontAwesomeIcon icon={['fas', 'hand-holding-heart']} />}
            >
              DONATE
            </Button>
          </NextLink>
        </Stack>
        <Stack
          direction={'row'}
          spacing={5}
          justifyContent={'start'}
          alignItems={'center'}
          height={'100%'}
        >
          {navigationItems.map((navItem, index) => {
            if (navItem.hasSubNav) {
              return (
                <Box 
                  key={index} 
                  className="main-nav-item"
                  height={'100%'}
                  cursor="pointer"
                  data-nav-item={navItem.hasSubNav ? navItem.label : null}
                  data-active={navItem.label === openNavItem ? true : null}
                  alignItems={'center'}
                  display={'inline-flex'}
                  _active={{ color: 'brand.orange.600' }}
                  _hover={{ color: 'brand.orange.600' }}
                >
                  <Flex
                    direction={'row'}
                    alignItems={'center'}
                    _hover={{ color: 'brand.orange.600' }}
                    color={'brand.darkgray.600'}
                    transition="color 0.2s"
                    className="main-nav-item"
                    data-nav-item={navItem.hasSubNav ? navItem.label : null}
                    data-active={navItem.label === openNavItem ? true : null}
                    _active={{ color: 'brand.orange.600' }}
                  >
                    {navItem.hasIcon && navItem.icon}
                    {!navItem.isSearchNavItem && (
                      <Text
                        p={1}
                        as={'span'}
                        fontSize={'sm'}
                        textTransform={'uppercase'}
                        fontWeight={500}
                        alignContent={'center'}
                        className="main-nav-item"
                        lineHeight={1}
                        data-nav-item={navItem.hasSubNav ? navItem.label : null}
                      >
                        {navItem.label}
                      </Text>
                    )}
                    {navItem.hasSubNav && !navItem.isSearchNavItem && (
                      <FontAwesomeIcon 
                        className="main-nav-item"
                        data-nav-item={navItem.label}
                        icon={['fas', 'chevron-down']}
                        style={{
                          transition: 'transform .1s ease-in-out',
                          transform: navItem.label === openNavItem ? 'rotate(180deg)' : 'rotate(0deg)'
                        }}
                      />
                    )}
                  </Flex>
                </Box>
              )
            } else {
              return (
                <Flex
                  alignItems={'center'}
                  gap="2"
                  fontSize={'sm'}
                  textTransform={'uppercase'}
                  fontWeight={500}
                  alignContent={'center'}
                  _hover={{ color: 'brand.green.600' }}
                  key={index}
                >

                  <Link href={navItem.href} passHref >
                    <ChakraLink
                      textDecoration={'none'}
                      _hover={{ textDecoration: 'none' }}
                    >
                      {navItem.hasIcon && navItem.icon}
                      {navItem.label}
                    </ChakraLink>
                  </Link>
                </Flex>
              )
            }
          })}
        </Stack>
      </Stack>
    </Flex>
  );
};

const DesktopSubNav = ({ openNavItem, navigationItems }) => {
  return (
    openNavItem !== undefined &&
    <Container
      display={{ base: 'none', lg: 'block' }}
      maxW={'container.xl'}
      position={'absolute'}
      zIndex="2000"
      top="74px"
      bgColor="white"
      border="1px solid"
      boxShadow={'lg'}
      borderColor={'brand.orange.600'}
      rounded={'xl'}
      p={4}
      transition={'opacity 0.3s'}
      opacity={{ base: '1', lg: openNavItem === undefined ? '0' : '1' }}
      id="main-sub-nav"
    >
      {navigationItems.filter(navItem => navItem.hasSubNav).map((navItem, index) => {
        return cloneElement(navItem.subNav, {
          openNavItem: openNavItem,
          key: index
        })
      })}
    </Container>
  );
};

export default DesktopNav;