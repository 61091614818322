// src/components/Navbar.js

import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Link,
  Stack} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NextLink from 'next/link';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SignInButton from './sign-in/SignInButton';
import SelectLanguageSubNav from './SelectLanguageSubNav';

interface Props {
  visible: boolean;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const TopBarContainer = (props:Props):JSX.Element => {
  const [showSelectLanguageSubNav, setShowSelectLanguageSubNav] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('click', handleSelectLanguageCLick);
    return () => window.removeEventListener('click', handleSelectLanguageCLick);
  })

  const handleSelectLanguageCLick = (e: any) => {
    const selectLanguageClicked = e.target.classList.contains('select-language-nav') || e.target.parentElement?.classList.contains('select-language-nav');
    const selectLanguageSubNavClicked: boolean = e.target.closest('.select-language-sub-nav');
    
    if (selectLanguageClicked) {
      setShowSelectLanguageSubNav(!showSelectLanguageSubNav);
    } else if (!selectLanguageSubNavClicked) {
      setShowSelectLanguageSubNav(false);
    }
  }

  return (
    <>
      <TopBar visible={props.visible} globalState={props.globalState} setGlobalState={props.setGlobalState}></TopBar>
      <SelectLanguageSubNav 
        showSelectLanguageSubNav={showSelectLanguageSubNav}
        globalState={props.globalState} 
        setGlobalState={props.setGlobalState} 
        setShowSelectLanguageSubNav={setShowSelectLanguageSubNav}></SelectLanguageSubNav>
    </>
  );
}

const TopBar = (props:Props):JSX.Element => {
  return (
    <>
      <Box 
        position="fixed"
        height="50px"
        width="100%"
        backgroundColor="white"
        textAlign="center"
        transition="top 0.6s"
        zIndex="1000"
        borderBottom={'1px solid'}
        borderColor={'brand.orange.100'}
        top={props.visible ? '0' : '-50px'}
        display={{ base: 'none', lg: 'flex' }}
      >
        <Container maxWidth="container.xl" h="100%">
          <Grid
            templateColumns={'repeat(1fr)'}
            gap={4}
            height="100%"
          >
            <GridItem colSpan={1}>
              <Flex align="center" direction="row" justify="end" gap="32px" height="100%">

                <Stack
                  direction={'row'}
                  spacing={2}
                  justifyContent={'start'}
                  alignItems={'center'}
                  height={'100%'} 
                >
                  <NextLink href={'tel:8887764090'} passHref >
                    <Link
                      color={'brand.green.600'}
                      fontWeight={'400'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      fontSize="sm"
                    >
                      <FontAwesomeIcon icon={['fas', 'phone-alt']} />
                      888.PPM.4090
                    </Link>
                  </NextLink>

                  <div style={{borderRight: "1px solid rgb(85, 193, 172)", height: "21px"}}></div>

                  <NextLink href={'/contact-us'} passHref >
                    <Link
                      color={'brand.green.600'}
                      fontWeight={'400'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={1}
                      fontSize="sm"
                      lineHeight={1}
                    >
                      <FontAwesomeIcon icon={['fas', 'message']} />
                      Contact Us
                    </Link>
                  </NextLink>
                </Stack>

                <NextLink href={'/about-us'} passHref >
                  <Link
                    color={'brand.green.600'}
                    fontWeight={'400'}
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                    fontSize="sm"
                    lineHeight={1}
                  >
                    <FontAwesomeIcon icon={['fas', 'circle-info']} />
                    About Us
                  </Link>
                </NextLink>

                <Link
                  className={'select-language-nav'}
                  color={'brand.green.600'}
                  fontWeight={'400'}
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                  fontSize="sm"
                  lineHeight={1}
                >
                  <FontAwesomeIcon icon={['fas', 'globe']} />
                  Select Language
                </Link>

                <SignInButton
                  type="desktop"
                  globalState={props.globalState}
                  setGlobalState={props.setGlobalState}
                />
              </Flex>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </>
  )
};

export default TopBarContainer;
